import React from "react";

const Footer = (props) => {
    const { dataStore } = props;
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4  menu-footer-one">
                            <a href="/home/EyVrAxBTudc=" className="logo-footer">
                                <img src={dataStore?.StoreNailLogPath} alt="" />
                            </a>
                            <ul className="footer-list no-bullets">
                                <li>
                                    <span className="icon">
                                        <i className="ti-home" aria-hidden="true"></i>
                                    </span>
                                    <p>{dataStore?.Address}</p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="fa fa-volume-control-phone" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Phone:
                                        <a href="tel:(+84)934 323 882">{dataStore?.Phone}</a>
                                    </p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="ti-email" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Email :
                                        <a href="mailto: arianailbarsugarland@gmail.com">{dataStore?.Email}</a>
                                    </p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="ti-world" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Website :
                                        <a href={dataStore?.StoreNail}>{dataStore?.Website}</a>
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4 menu-footer menu-footer-two">
                            <h3 className="menu-link">Location</h3>
                            <div className="footer-list content">
                                <iframe src={dataStore?.LocationPath}
                                    width="300" height="250">
                                    <p>Your browser does not support iframes.</p>
                                </iframe>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4 menu-footer menu-footer-three">
                            <h3 className="menu-link">Hours of Operation</h3>
                            <div className="footer-list content">
                                <ul className="homehours">
                                    {
                                        dataStore?.HoursOffOpen.map((item, index) => {
                                            return (
                                                <li key={'HoursOffOpen' + index}>{item.value}: {item.key}</li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="weaccept">
                                    <h3>We Accept:</h3>
                                    <div className="lstbank">
                                        <img src={require('../../assets/images/social/visa.png')} alt="visa" />
                                        <img src={require('../../assets/images/social/master.png')} alt="master" />
                                        <img src={require('../../assets/images/social/discover.png')} alt="discover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section id="footer-info" className="footer-info">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="footer-bottom-contact">
                                    Copyrights © 2024 by <a target="_blank" href="/home/EyVrAxBTudc=">Avalon Nail Spa.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Footer;