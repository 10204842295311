import { MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { InputMask, useMask } from "@react-input/mask";
import { Button, Col, Form, Input, Row, Tag, InputNumber } from "antd";
import React, { useEffect, useState, useRef } from "react";
const customizeRequiredMark = (label, { required }) => (
    <>
        {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
        {label}
    </>
);

const Start = (props) => {
    const { value, onNextStep, onChangeValue } = props
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');

    const inputRef = useMask({
        mask: '+0 (___) ___-__-__',
        replacement: { _: /\d/ },
    });

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        //console.log("requiredMarkValue", requiredMarkValue);
        // setRequiredMarkType(requiredMarkValue);
    };

    const handleChange = async (changedValues, allValues) => {
        //  console.log("handleChange", changedValues, allValues)
        onChangeValue?.(changedValues, allValues)
    };

    const handleSubmit = (allValues) => {
        // console.log("object", allValues)
        onNextStep?.(allValues)
    }

    // Hàm format số điện thoại
    const formatPhoneNumber = (value) => {
        if (!value) return value; // Trả về giá trị ban đầu nếu không có gì

        const cleaned = String(value).replace(/\D/g, ''); // Đảm bảo value là chuỗi và loại bỏ tất cả ký tự không phải số
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Kiểm tra định dạng 3-3-4 số
        console.log("match", match)
        if (match) {
            const valuenew = `${match[1]}-${match[2]}-${match[3]}`
            console.log("valuenew", valuenew)
            return valuenew;
        }
        return value; // Nếu không có khớp, trả về giá trị gốc
    };


    const handleChangePhoneNumber = (e) => {
        console.log("handleChangePhoneNumber", e)
    };

    return (

        <Form
            form={form}
            layout="vertical"
            initialValues={{
                requiredMarkValue: requiredMark,
            }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark === 'customize' ? customizeRequiredMark : requiredMark}
            onChange={handleChange}
            onFinish={handleSubmit}
            className="form-start"
        >
            <Row>
                <Col span={24} xs={24} sm={24} md={12} lg={8} style={{ paddingRight: 10 }}>
                    <Form.Item
                        label="Your Name (required):"
                        name="firstName"
                        initialValue={value?.firstName}
                        rules={[{ required: true, message: 'Please Enter Name!' }]}
                    >
                        <Input addonBefore={<UserOutlined />} />
                    </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24} md={12} lg={8} style={{ paddingRight: 10 }}>
                    <Form.Item
                        label="Phone Number (required):"
                        name="phonenumber"
                        initialValue={value?.phonenumber || ""}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter The Phone Nubmer!'
                            },
                            {
                                pattern: /^\(\d{3}\) \d{3} - \d{4}$/,
                                message: 'Phone number is not in the correct format!',
                            },
                        ]}
                    >
                        <div className="group-input-mask">
                            <div className="icon-phone">
                                <PhoneOutlined />
                            </div>
                            <InputMask
                                defaultValue={value?.phonenumber} 
                                mask="(___) ___ - ____"
                                replacement={{ _: /\d/ }}
                               // showMask
                               // separate
                                onChange={(e) => form.setFieldsValue({ phonenumber: e.target.value })}
                                 className="input-mask"
                            />
                        </div>
                    </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                        label="Your Email (required):"
                        name="email"
                        initialValue={value?.email}
                        rules={[
                            {
                                type: 'email',
                                message: `The input is not valid E-mail!`,
                            },
                            { required: true, message: 'Please Enter the Email!' }
                        ]}
                    >
                        <Input addonBefore={<MailOutlined />} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Your Message"
                        name="note"
                        initialValue={value?.note}
                    >
                        <Input.TextArea rows={4} placeholder="Your Message" maxLength={6} />
                    </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                    <Form.Item label={null}>
                        <Button type="primary" htmlType="submit" className="btn-next">
                            Next
                        </Button>
                    </Form.Item>
                </Col>

            </Row>
        </Form>

    );
}
export default Start;