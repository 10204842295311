import './assets/css/style.css'

import React from 'react';
import Router from './routers';
import { DataStoreProvider } from './contexts/DataContext';

const App = () => {
  return (
    <React.Suspense fallback={<div>Đang tải dữ liệu...</div>}>
      <DataStoreProvider>
        <Router />
      </DataStoreProvider>
    </React.Suspense>
  );
}


export default App;
