import React, { useEffect, useState } from "react";

const HeaderBooking = (props) => {
    const { dataStore } = props;

    // console.log("HeaderBooking", dataStore, dataStore?.StoreNailLogPath)

    //  const urlLogo = require('../../assets/images/logonail/VBSProcessinglogo.jpg')
    let urlLogo = '';
    if (dataStore?.StoreNailLogPath.length > 0) {
        urlLogo = dataStore?.StoreNailLogPath;
    }
    //  console.log("urlLogo", urlLogo,dataStore?.StoreNailLogPath.length )
    useEffect(() => {
        document.title = dataStore?.StoreNail;
    }, []);

    return (
        <header className="header-boooking">
            <div className="header-top">
                <div className="container-fluid">
                    <div className="row header-top">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            <div className="logo">
                                <a href="/">
                                    <span className="img-logo" style={{ backgroundImage: `url(${urlLogo})` }}></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="site-logo">
                                <a href="/">
                                    <h2 className="sub-title-celeste">{dataStore?.StoreNail}</h2>
                                    {/* <h4 className="sub-title-celeste">Foot Spa</h4> */}
                                </a>
                                <p className="header-description">
                                    {dataStore?.Address}
                                    <span className="number">
                                        <a>Phone: {dataStore?.Phone}</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12  col-sm-12 col-md-12 col-lg-3"></div>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default HeaderBooking;