import React, { useEffect, useState } from "react";
import { HOSTNAME } from "../../../utils/constants/SystemVars";
import { _fetchData } from '../../../utils/CallAPI';
import { useParams } from "react-router-dom";
import { CheckOutlined, FieldTimeOutlined, MinusOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Calendar, Col, List, Row, Space, Typography, InputNumber, Select, Radio, Button } from "antd";
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';


const { Option } = Select;

const AppointmenTime = (props) => {
    let { value, onChangeValue, onConfirm, onBack, dataStore } = props;
    const [dataSource, setDataSource] = useState([]);
    const [valueCalendar, setValueCalendar] = useState(value?.bookingDate === "" ? dayjs().add(1, 'd') : dayjs(value?.bookingDate));
    const [selectTime, setSelectTime] = useState('');

    const { KeyAPI } = useParams();
    const wrapperStyle = {
        width: 300,
    };

    useEffect(() => {
        let objsuggest = {
            bookingDate: dayjs(valueCalendar).format('YYYY-MM-DD'),
            pax: 1,
            services: value.services
        };
        loadTimesuggest(objsuggest);
    }, []);

    const handleClickItem = (item, index) => {
        //  console.log("handleClickItem", item, value)
        const objdataSourc = cloneDeep(value);
        objdataSourc.bookingDate = item.startTime;
        onChangeValue?.(objdataSourc)
        setSelectTime(index)
    }
    const onPanelChangepax = (item) => {
        // console.log("onPanelChangepax", item, value)
        let objsuggest = {
            bookingDate: dayjs(valueCalendar).format('YYYY-MM-DD'),
            pax: item,
            services: value?.services
        };
        //console.log("onPanelChangeobjsuggest", objsuggest)
        loadTimesuggest(objsuggest);
        const objdataSourc = cloneDeep(value);
        objdataSourc.pax = item;
        objdataSourc.bookingDate = "";
        onChangeValue?.(objdataSourc)
    }
    const handleClickChangepaxAdd = () => {
        console.log("onPanelChangepax", value)
        let objsuggest = {
            bookingDate: dayjs(valueCalendar).format('YYYY-MM-DD'),
            pax: value?.pax + 1,
            services: value?.services
        };
        //console.log("onPanelChangeobjsuggest", objsuggest)
        loadTimesuggest(objsuggest);
        const objdataSourc = cloneDeep(value);
        objdataSourc.pax = objsuggest.pax;
        objdataSourc.bookingDate = "";
        onChangeValue?.(objdataSourc)
    }

    const handleClickChangepaxAddAfter = () => {
        console.log("onPanelChangepax", value)
        let objsuggest = {
            bookingDate: dayjs(valueCalendar).format('YYYY-MM-DD'),
            pax: value?.pax - 1,
            services: value?.services
        };
        //console.log("onPanelChangeobjsuggest", objsuggest)
        loadTimesuggest(objsuggest);
        const objdataSourc = cloneDeep(value);
        objdataSourc.pax = objsuggest.pax;
        objdataSourc.bookingDate = "";
        onChangeValue?.(objdataSourc)
    }



    const loadTimesuggest = (objservices) => {
        const _header = {
            'Content-Type': 'application/json',
            'apikey': KeyAPI
        }
        _fetchData(HOSTNAME, 'booking/suggest', objservices, 'POST', _header).then(apiResult => {
            console.log("apiResult", apiResult);
            if (apiResult != null) {
                setDataSource(apiResult.data);
            }
            else {
                setDataSource([]);
            }
        })
    }
    const onPanelChange = (item, mode) => {
        setValueCalendar(item);
        let objsuggest = {
            bookingDate: item.format('YYYY-MM-DD'),
            pax: value?.pax,
            services: value.services
        };
        //console.log("onPanelChangeobjsuggest", objsuggest)
        loadTimesuggest(objsuggest);
    }
    const disabledDate = (current) => {
        // Disable dates before today
        return current && current < dayjs();
    };
    const selectBefore = (
        <div onClick={() => handleClickChangepaxAdd()}><PlusOutlined /></div>
    );
    const selectAfter = (
        <div onClick={() => handleClickChangepaxAddAfter()}><MinusOutlined /></div>
    );
    return (
        <Row>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
                <Row className="group-numpad">
                    <Col span={8} xs={12} sm={12} md={8} lg={8}>
                        <Typography.Title level={5} >
                            <div style={{ backgroundColor: "#07587D", padding: '0 5px', width: 'fit-content', height: 30, lineHeight: '30px', color: '#fff', borderRadius: 5 }}>Group of </div>
                        </Typography.Title>
                    </Col>

                    <Col span={16} xs={12} sm={12} md={16} lg={16}>

                        <InputNumber size="middle" addonBefore={selectBefore} addonAfter={selectAfter}
                            min={1} max={value?.services.length > 5 ? 5 : value?.services.length}
                            defaultValue={value?.pax} onChange={onPanelChangepax} style={{width: '150px'}}/>
                    </Col>
                </Row>
                <Row className="group-numpad">
                    <Col span={24}>
                        <p style={{ fontSize: 12, marginBottom: 10 }} className="txt-note">
                            <b style={{ fontSize: 14 }}>Note: </b>
                            We currently accept online bookings for groups of up to {dataStore?.Numpad} people. For groups of {dataStore?.Numpad} or more, let us elevate your experience. By calling us directly, we can tailor our services to your specific needs and ensure a memorable event. Let's create something truly special together</p>

                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <div className="calendar-cus">
                        <Calendar value={valueCalendar} disabledDate={disabledDate} fullscreen={false} onChange={onPanelChange}
                            headerRender={({ value, type, onChange }) => {
                                const start = 0;
                                const end = 12;
                                const monthOptions = [];
                                let current = value.clone();
                                const localeData = value.localeData();
                                const months = [];
                                for (let i = 0; i < 12; i++) {
                                    current = current.month(i);
                                    months.push(localeData.monthsShort(current));
                                }
                                for (let i = start; i < end; i++) {
                                    monthOptions.push(
                                        <Select.Option key={i} value={i} className="month-item">
                                            {months[i]}
                                        </Select.Option>,
                                    );
                                }
                                const year = value.year();
                                const month = value.month();
                                const options = [];
                                for (let i = year - 10; i < year + 10; i += 1) {
                                    options.push(
                                        <Select.Option key={i} value={i} className="year-item">
                                            {i}
                                        </Select.Option>,
                                    );
                                }
                                return (
                                    <div
                                        style={{
                                            padding: 8,
                                        }}
                                    >
                                        <Row gutter={8}>
                                            <Col>
                                                <Select
                                                    size="middle"
                                                    popupMatchSelectWidth={false}
                                                    className="my-year-select"
                                                    value={year}
                                                    onChange={(newYear) => {
                                                        const now = value.clone().year(newYear);
                                                        onChange(now);
                                                    }}
                                                >
                                                    {options}
                                                </Select>
                                            </Col>
                                            <Col>
                                                <Select
                                                    size="middle"
                                                    className="my-month-select"
                                                    popupMatchSelectWidth={false}
                                                    value={month}
                                                    onChange={(newMonth) => {
                                                        const now = value.clone().month(newMonth);
                                                        onChange(now);
                                                    }}
                                                >
                                                    {monthOptions}
                                                </Select>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }}
                            className="calendar-day"
                        />
                    </div>
                </Row>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={4} className="title-time-suggest">List of time suggest: {dayjs(valueCalendar).format('MM-DD-YYYY')}</Typography.Title>
                <List
                    dataSource={dataSource}
                    className="list-time-suggest"
                    size="default"
                    grid={{
                        gutter: 16,
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    style={{ marginTop: 30 }}
                    renderItem={(item, index) => (
                        <List.Item onClick={() => handleClickItem(item, index)} style={{ cursor: 'pointer' }}>
                            <div className="item">
                                <div style={{ position: 'relative' }} className="item-time">
                                    {dayjs(item?.startTime).format('YYYY-MM-DD HH:mm') === dayjs(value?.bookingDate).format('YYYY-MM-DD HH:mm') && (<CheckOutlined className="check-icon" style={{ color: '#07587D', fontSize: 14 }} />)}

                                    <FieldTimeOutlined style={{ color: dayjs(item?.startTime).format('YYYY-MM-DD HH:mm') === dayjs(value?.bookingDate).format('YYYY-MM-DD HH:mm') ? '#07587D' : '#949494' }} />

                                    <Typography.Text style={{ color: dayjs(item?.startTime).format('YYYY-MM-DD HH:mm') === dayjs(value?.bookingDate).format('YYYY-MM-DD HH:mm') ? '#07587D' : '#949494', paddingLeft: 5 }}>{dayjs(item.startTime).format('HH:mm')}</Typography.Text>

                                </div>
                            </div>
                        </List.Item>
                    )}
                />

            </Col>
            <Col span={24} >
                <div className="steps-action">
                    <Button
                        onClick={() => onBack?.()}
                        className="btn-prev"
                    >
                        Back
                    </Button>
                    <Button style={{
                        margin: '0 8px',
                    }}
                        type="primary" onClick={() => onConfirm?.()} className="btn-next">
                        Next
                    </Button>
                </div>
            </Col>
        </Row>
    );
}
export default AppointmenTime;