import React from "react";

const Banner = (props) => {
    let { dataStore } = props;
    const banner = dataStore?.banner;
    // <section className="banner-page"  style={{ backgroundImage: `url(${banner})` }}>
    return (

        <section className="banner-page" style={{backgroundImage: `url(${banner})`,backgroundRepeat:"no-repeat"}}>
            <div className="container">
                <div className="banner">
                    <div className="banner-title">
                        <h1>Booking</h1>

                    </div>
                </div>
            </div>
        </section>
    );
}
export default Banner;