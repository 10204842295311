import React, { useEffect, useState } from "react";
import { HOSTNAME } from "../../../utils/constants/SystemVars";
import { _fetchData } from '../../../utils/CallAPI';
import { useParams } from "react-router-dom";
import { Avatar, Button, Col, Flex, Image, List, Modal, Row, Space, Table, Typography, notification } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import { clone, cloneDeep, set } from 'lodash';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200, // Tablet and above
            settings: {
                slidesToShow: 3, // Show 3 slides on tablets
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992, // Mobile devices
            settings: {
                slidesToShow: 2, // Show 1 slide on mobile
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 767, // Smaller mobile devices (optional)
            settings: {
                slidesToShow: 1, // Show 1 slide on smaller mobile
                slidesToScroll: 1,
                arrows: true,
            },
        },
    ],

};

const PickServices = (props) => {
    const [api, contextHolder] = notification.useNotification();
    let { dataBook, onChangeValue } = props;
    const { KeyAPI } = useParams();
    const [isLoadFinish, setIsLoadFinish] = useState(false);
    const [serviceGroupIndex, setServiceGroupIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [datatechnicians, setDatatechnicians] = useState([]);
    const [datatechService, setDatatechService] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [childServices, setChildServices] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(null);
    const [isModalOpenNew, setIsModalOpenNew] = useState(false);
    const [isOpenWarning, setIsOpenWarning] = useState(false);


    useEffect(() => {
        loadData(KeyAPI);
        loadDatatechnicians(KeyAPI);
    }, []);
    const loadData = () => {
        setIsLoadFinish(false);
        const _header = {
            'Content-Type': 'application/json',
            'apikey': KeyAPI
        }
        _fetchData(HOSTNAME, 'booking/services', '', 'GET', _header).then(apiResult => {
            if (apiResult != null) {
                setDataSource(apiResult.data);
                var result = apiResult.data?.[serviceGroupIndex].services.map(function (el, index) {
                    var o = Object.assign({}, el);
                    o.key = index;
                    return o;
                })
                setChildServices(result)
            }
            setIsLoadFinish(true);
        })
    }
    const loadDatatechnicians = () => {
        setIsLoadFinish(false);
        const _header = {
            'Content-Type': 'application/json',
            'apikey': KeyAPI
        }
        _fetchData(HOSTNAME, 'booking/technicians', '', 'GET', _header).then(apiResult => {
            // console.log("loadDatatechnicians", apiResult);
            if (apiResult != null) {
                setDatatechnicians(apiResult.data);
                //  console.log("filtered", filtered);
            }
            setIsLoadFinish(true);
        })
    }
    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
            render: (_, record, index) => (
                <>{record.serviceName}</>
            )
        },
        {
            title: 'Time',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
            render: (_, record, index) => (
                <Space size="small">
                    {record.serviceTime}
                </Space>
            )
        },
        {
            title: 'Tech',
            dataIndex: 'technicianName',
            key: 'technicianName',
            render: (_, record, index) => (
                <div className="group-avatar-name">
                    <Avatar className="avatar-table" src={record?.technicianAvatar === "" ? "https://celestefootspa.com/images/user.png" : record?.technicianAvatar} alt={record.technicianName} />
                    <span className="name-table">{record.technicianName}</span>
                </div>
            ),
        },
        {
            title: 'Price',
            key: 'servicePrice',
            dataIndex: 'servicePrice',
            render: (_, record, index) => (
                <Space size="small">
                    {record.servicePrice}
                </Space>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (bj, record, index) => (
                <Space size="middle">
                    <DeleteOutlined
                        onClick={(e) => {
                            e.stopPropagation();  // Ngừng sự kiện lan truyền
                            handleClickDeleteService(record, bj);
                        }}
                        className="icon-delete-table"
                    />
                </Space>
            ),
        },
    ];

    const columns2 = [
        {
            title: 'Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
            render: (_, record) => (
                <Space size="small">
                    <CheckOutlined className="icon-check-table" style={{ color: '#07587D', fontSize: 16 }} />
                    {(record?.serviceIcon === null || record?.serviceIcon === "") ? "" : <Avatar src={record?.serviceIcon} alt={record.serviceName} />}
                    {record.serviceName}
                </Space>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
            render: (_, record) => (
                <Space size="small">
                    {record.serviceTime}'
                </Space>
            ),
        },
        {
            title: 'Price',
            key: 'servicePrice',
            dataIndex: 'servicePrice',
        },
    ];

    const handleRowClick = (record, rowIndex) => {
        // Thực hiện hành động khi click vào một hàng

        const filtered = datatechnicians?.filter(obj => {
            return obj?.services.find(a => a.serviceId === record.serviceId) || obj?.technicianId === '-1';
        });
        var resulttechnicians = filtered?.map(function (el, index) {
            var o = Object.assign({}, el);
            o.serviceId = record.serviceId;
            o.serviceName = record.serviceName;
            o.serviceTime = record.serviceTime;
            o.servicePrice = record.servicePrice;
            return o;
        })
        setSelectedRowKeys(record);
        setDatatechService(resulttechnicians);
        setIsModalOpen(true)

    };

    const handleRowClickservices = (record, rowIndex) => {
        // Thực hiện hành động khi click vào một hàng
        // console.log("objdataSourc", record, rowIndex)
        const filtered = datatechnicians?.filter(obj => {
            return obj?.services.find(a => a.serviceId === record.serviceId) || obj?.technicianId === '-1';
        });
        var resulttechnicians = filtered?.map(function (el, index) {
            var o = Object.assign({}, el);
            o.serviceId = record.serviceId;
            o.serviceName = record.serviceName;
            o.serviceTime = record.serviceTime;
            o.servicePrice = record.servicePrice;
            return o;
        })

        setDatatechService(resulttechnicians);
        setSelectedRowKeys(record)
        setIsModalOpenNew(true)
    };


    const handleCancelNew = () => {
        setIsModalOpenNew(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClickDeleteService = (record) => {
        setIsModalOpenNew(false)
        const objdataSourc = cloneDeep(dataBook);
        const indexservices = objdataSourc?.services.findIndex(n => n.serviceId === record.serviceId && n.technicianId === record.technicianId);
        objdataSourc?.services.splice(indexservices, 1);
        onChangeValue?.(objdataSourc)
    };

    const handleRowClickItem = (record) => {

        let objserviceId = {
            key: record.serviceId + '-' + record.technicianId,
            serviceId: record.serviceId,
            serviceName: record.serviceName,
            serviceTime: record.serviceTime,
            servicePrice: record.servicePrice,
            quantity: 1,
            technicianId: record.technicianId,
            technicianName: record.technicianName,
            technicianAvatar: record.technicianAvatar,
        }
        const objdataSourc = cloneDeep(dataBook);

        const indexservices = objdataSourc?.services?.findIndex(n => n.serviceId === record.serviceId && n.technicianId === record.technicianId);
        if (indexservices === -1) {
            const groupedInventory = objdataSourc?.services?.reduce((acc, item) => {
                // Ensure there's an array for each type in the accumulator
                if (!acc.includes(item.technicianId) && item.technicianId !== '-1') {
                    acc.push(item.technicianId);
                }
                return acc;
            }, []);
            if (groupedInventory.length >= 2 && record.technicianId !== '-1') {
                if (groupedInventory.includes(record.technicianId)) {
                    objdataSourc.services.push(objserviceId);
                }
                else {
                    setIsOpenWarning(true);
                }
            }
            else {
                objdataSourc.services.push(objserviceId);
            }
            // console.log("groupedInventory", record, groupedInventory, groupedInventory.length);
        }

        onChangeValue?.(objdataSourc)
        setIsModalOpen(false);
    };
    const handleRowClickEditItem = (record) => {
        if (record.technicianId !== selectedRowKeys?.technicianId) {

            const objdataSourc = cloneDeep(dataBook);
            const indexservices = objdataSourc?.services.findIndex(n => n.serviceId === selectedRowKeys.serviceId && n.technicianId === selectedRowKeys.technicianId);
            const groupedInventory = objdataSourc?.services?.reduce((acc, item) => {
                // Ensure there's an array for each type in the accumulator
                if (!acc.includes(item.technicianId) && item.technicianId !== '-1') {
                    acc.push(item.technicianId);
                }
                return acc;
            }, []);
            if (groupedInventory.length >= 2 && record.technicianId !== '-1') {
                if (groupedInventory.includes(record.technicianId)) {
                    objdataSourc.services[indexservices]["technicianId"] = record.technicianId;
                    objdataSourc.services[indexservices]["technicianName"] = record.technicianName;
                }
                else {
                    setIsOpenWarning(true);
                }
            }
            else {
                objdataSourc.services[indexservices]["technicianId"] = record.technicianId;
                objdataSourc.services[indexservices]["technicianName"] = record.technicianName;
            }

            onChangeValue?.(objdataSourc)
        }

        setIsModalOpenNew(false)
    };


    const openNotificationsuccess = (placement) => {
        api.warning({
            message: `warning!`,
            description:
                'To ensure we can best accommodate your specific needs, online bookings are limited to 2 preferred technicians. If you require assistance with scheduling 3 or more technicians, please call to us. Were happy to help!',
            placement
        });
    };

    const handleCloseWarning = () => {
        setIsOpenWarning(false);
    }




    const handleClickServiceGroup = (record, index) => {
        var result = dataSource[index].services.map(function (el, index) {
            var o = Object.assign({}, el);
            o.key = index;
            return o;
        });
        setChildServices(result);
        setServiceGroupIndex(index);
    };


    return (
        !!isLoadFinish && <>
            <div className="PickServices">
                {contextHolder}
                <div className="row">
                    <div className="col-12">
                        <div className="group-services">
                            <div className="group-item  group-services-pick">
                                <Row>
                                    <Col span={24}>
                                        {dataSource && (
                                            <Slider {...settings} className="slider-services">
                                                {
                                                    dataSource?.map((item, index) => {
                                                        return (
                                                            <div className='slider-item' key={item.serviceGroupId} onClick={() => handleClickServiceGroup(item, index)}>
                                                                <div className="img-service">
                                                                    <Image className="avatar-service" src={item.serviceGroupIcon} alt="avatar" preview={false} />
                                                                </div>
                                                                <div className="name-service h3-service-select">
                                                                    {serviceGroupIndex === index && (<CheckOutlined className="icon-check" style={{ color: '#07587D', fontSize: 16 }} />)}
                                                                    <Typography.Title level={5} style={{ color: serviceGroupIndex === index ? '#07587D' : '#949494', fontWeight: 400, whiteSpace: 'nowrap', fontSize: 14 }}>{item.serviceGroupName}</Typography.Title>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </Slider>
                                        )}
                                    </Col>
                                    <Col span={24} style={{ marginTop: 20 }}>

                                        <Table columns={columns2}
                                            dataSource={childServices}
                                            pagination={false}
                                            size="middle"
                                            onRow={(record, rowIndex) => ({
                                                onClick: () => handleRowClick(record, rowIndex),
                                            })}
                                            rowClassName={(record, index) =>
                                                dataBook?.services?.findIndex(n => n.serviceId === record?.serviceId) > -1
                                                    ? 'selected'
                                                    : ''
                                            }
                                            className="table-child-services"
                                            scroll={{ x: 'max-content' }}
                                        />

                                    </Col>
                                </Row>
                            </div>
                            <div className="group-item group-services-select">
                                <Table columns={columns}
                                    dataSource={dataBook?.services}
                                    pagination={false}
                                    size="middle"
                                    onRow={(record, rowIndex) => ({
                                        onClick: () => handleRowClickservices(record, rowIndex),
                                    })}
                                    className="table-child-services-select"
                                    summary={() => (
                                        <Table.Summary fixed>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0} colSpan={3}>
                                                    <Typography.Title level={5} style={{ color: '#07587D', fontWeight: 'bold' }}>EST. Total</Typography.Title>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1} colSpan={2}>${(dataBook?.services && dataBook?.services.reduce((accumulator, item) => {
                                                    return accumulator + item.servicePrice;
                                                }, 0))}</Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    )}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal title="List Employees" open={isModalOpen} width={1000} footer={null} onCancel={handleCancel}>
                <Row>
                    <Col span={24}>
                        <List
                            dataSource={datatechService}
                            grid={{
                                gutter: 16,
                                xs: 2,
                                sm: 3,
                                md: 4,
                                lg: 6,
                                xl: 8,
                            }}
                            className="list-employees"
                            renderItem={(item) => (
                                <List.Item onClick={() => handleRowClickItem(item)} >
                                    <div className="item">
                                        <div className="avatar">
                                            <Image src={item?.technicianAvatar === "" ? "https://celestefootspa.com/images/ourservice/01.jpg" : item?.technicianAvatar} preview={false} alt={item.technicianName} />
                                        </div>
                                        <div className="content">
                                            {dataBook?.services?.findIndex(n => n.technicianId === item?.technicianId && n.serviceId === selectedRowKeys?.serviceId) > -1 ? <CheckOutlined className="icon-check" style={{ color: '#07587D', fontSize: 14 }} /> : ""}
                                            <Typography.Title level={5} style={{ color: dataBook?.services?.findIndex(n => n.technicianId === item?.technicianId && n.serviceId === selectedRowKeys?.serviceId) > -1 ? '#07587D' : '#767676', fontWeight: 'normal', fontSize: 12, marginLeft: 10 }}>{item.technicianName}</Typography.Title>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Modal>

            <Modal title="Edit List Employees" open={isModalOpenNew} width={1000} footer={null} onCancel={handleCancelNew} maskClosable={true}>
                <Row>
                    <Col span={24}>
                        <List
                            dataSource={datatechService}
                            grid={{
                                gutter: 16,
                                xs: 2,
                                sm: 3,
                                md: 4,
                                lg: 6,
                                xl: 8,
                            }}
                            className="list-employees"
                            renderItem={(item) => (
                                <List.Item onClick={() => handleRowClickEditItem(item)} >
                                    <div className="item">
                                        <div className="avatar">
                                            <Image src={item?.technicianAvatar === "" ? "https://celestefootspa.com/images/ourservice/01.jpg" : item?.technicianAvatar} preview={false} alt={item.technicianName} />
                                        </div>
                                        <div className="content">
                                            {item.technicianId === selectedRowKeys?.technicianId ? <CheckOutlined className="icon-check" style={{ color: '#07587D', fontSize: 14 }} /> : ""}
                                            <Typography.Title level={5} style={{ color: item.technicianId === selectedRowKeys?.technicianId ? '#07587D' : '#767676', fontWeight: 'normal', fontSize: 12, marginLeft: 10 }}>{item.technicianName}</Typography.Title>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<div style={{ color: '#07587d' }}>Warning</div>}
                open={isOpenWarning}
                width={500}
                onCancel={handleCloseWarning}
                maskClosable={false}
                footer={(
                    <Button key="cancel" onClick={handleCloseWarning} className="btn-cancel">
                        Close
                    </Button>
                )}
            >
                <Row>
                    <Col span={24}>
                        <Typography.Paragraph>
                            To ensure we can best accommodate your specific needs, online bookings are limited to 2 preferred technicians. If you require assistance with scheduling 3 or more technicians, please call to us. Were happy to help!
                        </Typography.Paragraph>
                    </Col>
                </Row>

            </Modal>
        </>
    );

}
export default PickServices;