import React, { useEffect, useState } from "react";
import { HOSTNAME } from "../../utils/constants/SystemVars";
import { _fetchData } from '../../utils/CallAPI';
import { useParams, useNavigate } from "react-router-dom";
import { CheckCircleOutlined, MenuFoldOutlined, PlayCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Steps, Typography, notification } from "antd";
import Start from "./components/Start";
import AppointmenTime from "./components/AppointmenTime";
import PickServices from "./components/PickServices";
import Finish from "./components/Finish";
import Banner from "./components/Banner";
import { clone, cloneDeep } from 'lodash';
import { useData } from "../../contexts/DataContext";

const Booking = () => {
    const { dataStore } = useData();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const { KeyAPI } = useParams();
    const [current, setCurrent] = useState(0);
    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [modal, contextHolderModal] = Modal.useModal();
    const [dataBooking, setDataBooking] = useState({
        phonenumber: "",
        firstName: "",
        lastName: "",
        bookingDate: "",
        note: "",
        email: "",
        pax: 1,
        services: []
    });


    // Handle steps change
    const onChange = (value) => {
        if (dataBooking?.firstName === "" || dataBooking?.phonenumber === "") {
            setCurrent(0);
        }
        else {
            setCurrent(value);
        }

    };
    const onChangeValue = (obj) => {
        // console.log("handleClickItem",obj)
        setDataBooking(obj);
    };
    const onChangeValueNew = (obj) => {
        // setDataBooking(obj);
    };
    const handleNextStep = (data) => {
        //console.log("handleNextStep", data)

        const objdataSourc = cloneDeep(dataBooking);
        objdataSourc.phonenumber = data.phonenumber;
        objdataSourc.firstName = data.firstName;
        objdataSourc.lastName = data.firstName;
        objdataSourc.note = data.note;
        objdataSourc.email = data.email;
        //  console.log("handleNextStep", data, objdataSourc)
        setDataBooking(objdataSourc);
        setCurrent(current + 1);
    }


    const handleBack = () => {
        setCurrent(1)
    }

    const handleConfirm = () => {
        confirm()
    }


    const items = [
        {
            title: <Typography.Text className="title-step" style={{ paddingLeft: 40 }}>Start</Typography.Text>,
            icon: <PlayCircleOutlined />,
            content: <Start value={dataBooking} onChangeValue={onChangeValueNew} onNextStep={handleNextStep} />,
        },
        {
            title: <Typography.Text className="title-step">Pick</Typography.Text>,
            icon: <MenuFoldOutlined />,
            content: <PickServices dataBook={dataBooking} onChangeValue={onChangeValue} />,
        },
        {
            title: <Typography.Text className="title-step">Appointment time</Typography.Text>,
            icon: <ClockCircleOutlined />,
            content: <AppointmenTime dataStore={dataStore} value={dataBooking} onChangeValue={onChangeValue} onConfirm={handleConfirm} onBack={handleBack} />,
        },
        {
            title: <Typography.Text className="title-step" style={{ paddingLeft: 40 }}>Finish</Typography.Text>,
            icon: <CheckCircleOutlined />,
            content: <Finish dataBook={dataBooking} />,
        }
    ];

    const next = () => {
        if (dataBooking?.firstName === "" || dataBooking?.phonenumber === "") {
            setCurrent(0);
        }
        else if (dataBooking?.services.length === 0) {
            setCurrent(1);
        }
        else if (dataBooking?.bookingDate === "") {
            setCurrent(2);
        } else {
            setCurrent(current + 1);
        }

    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const clickBooking = () => {
        if (dataBooking?.firstName === "") {
            setCurrent(0);
        }
        else if (dataBooking?.services.length === 0) {
            setCurrent(1);
        }
        else if (dataBooking?.bookingDate === "") {
            setCurrent(2);
        }
        else {
            const words = dataBooking?.firstName.split(' ');
            let objbookingRequest = {
                phonenumber: dataBooking?.phonenumber.replace(/[()-\s]/g, ""),
                firstName: words.length === 1 ? words[0] : words[0],
                lastName: words.length === 1 ? words[0] : words[1],
                bookingDate: dataBooking?.bookingDate,
                //bookingDate: "",
                note: dataBooking?.note,
                services: dataBooking?.services,
                pax: dataBooking?.pax
            };

            const _header = {
                'Content-Type': 'application/json',
                'apikey': KeyAPI
            }
            _fetchData(HOSTNAME, 'booking/v2', objbookingRequest, 'POST', _header).then(apiResult => {
                console.log("apiResult", apiResult);
                if (apiResult != null) {
                    if (apiResult?.status === 200) {
                        setIsOpenWarning(true)
                        setCurrent(0);
                        setDataBooking({
                            phonenumber: "",
                            firstName: "",
                            lastName: "",
                            bookingDate: "",
                            note: "",
                            email: "",
                            pax: 1,
                            services: []
                        }
                        );
                    }
                    else {
                        openNotificationerror("bottomRight");
                    }
                }
                else {
                    openNotificationerror("bottomRight");
                }
            });
        }
    };
    const openNotificationsuccess = (placement) => {
        api.success({
            message: `Success!`,
            description:
                'Thank you for your booking. Please check your email and message for details. We are looking forward to serving you.However, in case we are not available at that time we will contact you soon.',
            placement
        });
    };

    const openNotificationerror = (placement) => {
        api.error({
            message: `SORRY!`,
            description:
                'We are not available at this time. Please pick another time or other preferred technicians or you can call us for more details. Sorry for any inconvenience!.',
            placement
        });
    };


    const handleOk = () => {
        if (dataBooking?.bookingDate === "") {
            openNotificationwarning("bottomRight");
            setCurrent(2);
        }
        else {
            setCurrent(3);
        }
    }


    const openNotificationwarning = (placement) => {
        api.warning({
            message: `SORRY!`,
            description: 'Please Enter Appointment time!.',
            placement
        });
    };


    const confirm = () => {
        modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Please confirm that you book for ' + dataBooking?.pax + ' people.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'btn-next',
            },
            onOk() {
                handleOk();
            },

        });
    };

    const handleCloseWarning = () => {
        setIsOpenWarning(false);
    }


    if (dataStore?.StoreNailID === "VBSProcessing") {
        return (<>
            <Banner dataStore={dataStore} />
            <section className="booking">
                <div className="container">
                    <div className="row">
                        vui lòng liên hệ với  VBS Processing để đăng ký hệ thống booking nail
                    </div>
                </div>
            </section>
        </>)
    }
    else {
        return (
            <>
                <Banner dataStore={dataStore} />
                {contextHolder}

                <section className="booking">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Steps
                                    current={current}
                                    onChange={onChange}
                                    items={items}
                                    labelPlacement="vertical"
                                    className="steps-booking"
                                    responsive={false}
                                />
                            </div>
                            <div className="col-12">
                                <div className="steps-content">
                                    {items[current].content}
                                    {(current > 0 && current !== 2) && (
                                        <div className="steps-action">
                                            <Button

                                                onClick={() => prev()}
                                                className="btn-prev"
                                            >
                                                Back
                                            </Button>
                                            {(current !== 0 && current < items.length - 1) && (
                                                <Button style={{
                                                    margin: '0 8px',
                                                }}
                                                    type="primary" onClick={() => next()} className="btn-next">
                                                    Next
                                                </Button>
                                            )}

                                            {current === 3 && (
                                                <Button style={{
                                                    margin: '0 8px',
                                                }}
                                                    type="primary" onClick={() => clickBooking()} className="btn-next">
                                                    Booking
                                                </Button>
                                            )}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {contextHolderModal}


                <Modal
                    title={<div style={{ color: '#07587d' }}>Success</div>}
                    open={isOpenWarning}
                    width={500}
                    onCancel={handleCloseWarning}
                    maskClosable={false}
                    footer={(
                        <Button key="cancel" onClick={handleCloseWarning} className="btn-cancel">
                            Close
                        </Button>
                    )}
                >
                    <Row>
                        <Col span={24}>
                            <Typography.Paragraph>
                                Thank you for your booking! <br />
                                We'll be checking our schedule shortly and will be in touch soon to confirm your appointment.<br />
                                We are looking forward to seeing you!
                            </Typography.Paragraph>
                        </Col>
                    </Row>

                </Modal>
            </>

        );
    }
};

export default Booking;
