import { Avatar, Col, Row, Space, Table, Typography } from "antd";
import React, { useEffect, useState, useRef } from "react";
import dayjs from 'dayjs';
import ReCAPTCHA from 'react-google-recaptcha';

const Finish = (props) => {
    let { dataBook } = props;
    const siteKey = 'YOUR_SITE_KEY';
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
        },
        {
            title: 'Time',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
        },
        {
            title: 'Tech',
            dataIndex: 'technicianName',
            key: 'technicianName',
            render: (_, record) => (
                <Space size="small">
                    <Avatar src={record?.technicianAvatar === "" ? "https://celestefootspa.com/images/user.png" : record?.technicianAvatar} alt={record.technicianName} />{record.technicianName}
                </Space>
            ),
        },
        {
            title: 'Price',
            key: 'servicePrice',
            dataIndex: 'servicePrice',
        }
    ];
    // const objFinishDate= new 
    let obj = dataBook?.services && dataBook?.services.reduce((accumulator, item) => {
        return accumulator + item.serviceTime;
    }, 0)
    var now = new Date(dataBook?.bookingDate);
    now.setMinutes(now.getMinutes() + obj); // timestamp
    now = new Date(now);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };

    return (
        <Row>
            <Col span={24} xs={24} sm={24} md={12} lg={12}>
                <Row>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>Name:</Typography.Title>
                            <Typography.Title level={5}>{dataBook?.firstName}</Typography.Title>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>Email:</Typography.Title>
                            <Typography.Title level={5}>{dataBook?.email}</Typography.Title>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>Appointment time:</Typography.Title>
                            <Typography.Title level={5}>{dayjs(dataBook?.bookingDate).format('h:mm A MM-DD-YYYY')}</Typography.Title>
                        </Space>
                    </Col>
                </Row>
            </Col>
            <Col span={24} xs={24} sm={24} md={12} lg={12}>
                <Row>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>Phone:</Typography.Title>
                            <Typography.Title level={5}>{dataBook?.phonenumber}</Typography.Title>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>Book time:</Typography.Title>
                            <Typography.Title level={5}>{dayjs(dataBook?.bookingDate).format('h:mm A MM-DD-YYYY')}</Typography.Title>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Space size="small" className="info-book">
                            <Typography.Title level={5}>EST Finish Time:</Typography.Title>
                            <Typography.Title level={5}>{dayjs(now).format('h:mm A MM-DD-YYYY')}</Typography.Title>
                        </Space>
                    </Col>
                </Row>
            </Col >
            <Col span={24} xs={24} sm={24} md={12} lg={12}>
                <Row>
                    <Col span={24}>
                        <Space size="small">
                            <Typography.Title level={5}>Note:</Typography.Title>
                            <Typography.Title level={5}>{dataBook?.note}</Typography.Title>
                        </Space>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ marginTop: 20 }}>
                <Table columns={columns}
                    dataSource={dataBook?.services}
                    pagination={false}
                    size="small"
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <Typography.Title level={5} style={{ color: '#07587D', fontWeight: 'bold' }}>EST. Total</Typography.Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>${(dataBook?.services && dataBook?.services.reduce((accumulator, item) => {
                                    return accumulator + item.servicePrice;
                                }, 0))}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Col>
            {/* <Col span={24} style={{ marginTop: 20 }}>
                <form >
                    <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={handleRecaptchaChange}
                    />
                </form>
            </Col> */}
        </Row >
    );
}
export default Finish;