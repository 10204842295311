import React, { createContext, useContext, useState, useEffect } from 'react';
import { dataStoreNail } from "../utils/constants/dataStoreNail";
import { useLocation } from 'react-router-dom';
const DataContext = createContext();

export const DataStoreProvider = ({ children }) => {

    const location = useLocation();
    const words = location?.pathname?.split('/');
    const [dataStore, setDataStore] = useState(() => {
        if (dataStoreNail?.find(n => n.StoreNailID === words[words.length - 1]) === undefined) {
            return {
                StoreNailID: "VBSProcessing",
                StoreNail: "VBS Processing",
                StoreNailLogPath: '../../assets/images/logonail/VBSProcessinglogo.jpg',
                banner:'http://celestefootspa.com/images/slide/WA-Celeste-Interior-6-2.jpg',
                LocationPath: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4074.789044569332!2d-84.12300242429002!3d33.90060847321457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a4c063798787%3A0x18bb62c4afd6ed9c!2s331%20Arcado%20Rd%2C%20Lilburn%2C%20GA%2030047%2C%20USA!5e1!3m2!1sen!2s!4v1735014425432!5m2!1sen!2s",
                Address: "331 Arcado Rd, Lilburn, GA 30047, USA",
                Phone: "(404) 374-6123",
                Email: "sales@vbsprocessing.com",
                Website: "www.vbsprocessing.com",
                hrefHome: "/",
                HoursOffOpen:[{value:"Mon-Sat",key:"09:30 am - 7:00 pm"},{value:"Sun",key:"11:00 am - 5:00 pm"}],
                Numpad:6
            }
        }
        else {
            return dataStoreNail?.find(n => n.StoreNailID === words[words.length - 1])
        }

    });

    return (
        <DataContext.Provider value={{ dataStore }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    return useContext(DataContext);
};
