const urlLogo = require('../../assets/images/logonew.png')
export const dataStoreNail = [
    {
        StoreNailID:"ko0HtIJzkmE=",
        StoreNail:"Avalon Nail Spa",
        StoreNailLogPath:urlLogo,
        banner:'http://celestefootspa.com/images/slide/WA-Celeste-Interior-6-2.jpg',
        LocationPath:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6116.569311502794!2d-86.020211!3d39.957388!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8814b38305880c9d%3A0x5a050fbef4352702!2s8402%20E%20116th%20St%2C%20Fishers%2C%20IN%2046038!5e0!3m2!1svi!2sus!4v1735017823184!5m2!1svi!2sus",
        Address:"13574 University Blvd Ste. 900,Sugar Land, TX 77479",
        Phone:"(281) 800-2742",
        Email:"arianailbarsugarland@gmail.com",
        Website :"avalonnailspasugarland.com",
        hrefHome:"/",
        HoursOffOpen:[{value:"Mon-Sat",key:"09:30 am - 7:00 pm"},{value:"Sun",key:"11:00 am - 5:00 pm"}],
        Numpad:6
    },
    {
        StoreNailID:"6D0FKsgXitY=",
        StoreNail:"Cali Spa",
        StoreNailLogPath:"https://www.calispa.me/Images/upload/banner/default/logo_cali_spa.png",
        banner:'https://www.calispa.me/Images/upload/banner/default/hair_services_cali_spa.png',
        LocationPath:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.148959523348!2d-85.1651296!3d41.1772578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8815e275b37c28e1%3A0xada37d69997134aa!2s1615%20W%20Dupont%20Rd%2C%20Fort%20Wayne%2C%20IN%2046825%2C%20Hoa%20K%E1%BB%B3!5e1!3m2!1svi!2s!4v1735023285986!5m2!1svi!2s",
        Address:"1615 W. Dupont Road Fort Wayne, IN 46825",
        Phone:"260-451-8888 or 260-451-9999",
        Email:"calispame@yahoo.com",
        Website :"www.calispa.me",
        hrefHome:"/",
        HoursOffOpen:[{value:"Monday to Friday",key:"9am - 8pm"},
            {value:"Saturday",key:"9am - 7pm"},
            {value:"Sunday",key:"10am - 4pm"}],
        Numpad:6
    }
]
