import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HeaderBooking from "./HeaderBooking";
import { useData } from "../../contexts/DataContext";

const Layout = (props) => {
        const { dataStore } = useData();
    if (props.layout === 'pagenotfound') {
        return (
            props.children
        );
    }
    else {
        return (
            <>
                {
                    props?.layout === 'Booking' ? <HeaderBooking dataStore={dataStore} /> :  <Header />
                }
              
                {
                    props.children
                }
                <Footer  dataStore={dataStore}  />
            </>
        );
    }
}

export default Layout;