import Booking from "./Booking";
import Home from "./Home";

export const screens = [
    {
        path : '/Home/:KeyAPI',
        component : Home
    },
    {
        path : '/booking/:KeyAPI',
        component : Booking,
         layout: 'Booking'
    },
]